import {
  Box,
  Button,
  Flex,
  Link,
  ModalBody,
  Spinner,
  Text,
} from "@chakra-ui/react";
import {
  ArrowIcon,
  EColor,
  ETextVariant,
  useIsMobile,
} from "@fanatics-live/common-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Alert,
  CheckoutModal,
  CheckoutModalHeader,
  InfoIcon,
} from "@/components";
import { CollectShippingType } from "@/gql";
import { useCheckoutDetails, useCartContext } from "@/hooks";
import styles from "@/legacy/styles";

import { AlternateAddressForm } from "./AlternateAddressForm";
import { ShippingMethods } from "./ShippingMethods";
import { ShippingOption } from "./ShippingOption";
import {
  hasCombinedShippingBanner,
  getValidShippingMethods,
  shippingTypeIsSet,
} from "./utils";

export const ShippingSection = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const { detailsOptions, setDetailsOptions } = useCartContext();

  const {
    details,
    loading: detailsLoading,
    error: detailsError,
  } = useCheckoutDetails(detailsOptions);

  const [optionsVisible, setOptionsVisible] = useState(false);
  const [alternateAddressFormVisible, setAlternateAddressFormVisible] =
    useState(false);
  const [hasAlternateAddressError, setHasAlternateAddressError] =
    useState<boolean>();
  const [hasDefaultAddressError, setHasDefaultAddressError] =
    useState<boolean>();

  // UPS, FEDEX, etc. Currently only Burbank.
  const validShippingMethods = getValidShippingMethods(
    details?.shippingType,
    details?.availableShippingMethods,
    detailsOptions.defaultShippingAddress,
    detailsOptions.alternateShippingAddress,
  );
  const hasShippingMethods = !!validShippingMethods.length;
  const availableShippingTypes = details?.availableShippingTypes ?? [];

  // Keep options expanded if only one option
  if (availableShippingTypes.length === 1 && !optionsVisible) {
    setOptionsVisible(true);
  }

  // If the alternate address or default address was invalid, GraphQL will return an
  // 'mp_unrecognized_client_error' or 'mp_get_tax_error'. Intercept, reset the details, and keep the
  // alternate address form or default address open with an error message.
  useEffect(() => {
    const isDetailErrorRelatesToAddress =
      detailsError?.message === "mp_unrecognized_client_error" ||
      detailsError?.message === "mp_get_tax_error";

    if (!detailsLoading && alternateAddressFormVisible) {
      if (isDetailErrorRelatesToAddress) {
        setHasAlternateAddressError(true);
        setDetailsOptions({
          ...detailsOptions,
          alternateShippingAddress: undefined,
          shippingType: undefined,
        });
      } else if (hasAlternateAddressError === false) {
        setAlternateAddressFormVisible(false);
        setHasAlternateAddressError(undefined);
        window.scrollTo({ top: window.scrollY - 478 }); // scroll position - form height
      }
    } else if (
      !detailsLoading &&
      detailsOptions.shippingType === CollectShippingType.Default
    ) {
      if (isDetailErrorRelatesToAddress) {
        setHasDefaultAddressError(true);
        setDetailsOptions({
          ...detailsOptions,
          shippingType: CollectShippingType.Vault,
        });
      } else {
        setHasDefaultAddressError(false);
        setOptionsVisible(false);
      }
    }
  }, [
    detailsError,
    detailsLoading,
    hasAlternateAddressError,
    alternateAddressFormVisible,
    detailsOptions,
    setDetailsOptions,
  ]);

  const bodyContent = (
    <>
      {alternateAddressFormVisible && (
        <Box
          {...(!isMobile && {
            borderY: "1px",
            borderColor: EColor.Neutral15,
            py: "20px",
          })}
          mb="24px"
        >
          {!isMobile && (
            <Text {...styles.h3}>
              {t("cart.shipping.alternate.form_title")}
            </Text>
          )}
          <AlternateAddressForm
            fulfillmentChargesLoading={detailsLoading}
            fulfillmentChargesErrorMessage={
              hasAlternateAddressError
                ? t("cart.shipping.alternate.invalid_message")
                : undefined
            }
            onSubmit={(alternateShippingAddress) => {
              setHasAlternateAddressError(false);
              setDetailsOptions({
                ...detailsOptions,
                shippingMethodId: getValidShippingMethods(
                  CollectShippingType.New,
                  details?.availableShippingMethods,
                  detailsOptions.defaultShippingAddress,
                  alternateShippingAddress,
                )[0]?.id,
                shippingType: CollectShippingType.New,
                alternateShippingAddress,
              });
              setOptionsVisible(false);
            }}
            onCancel={() => {
              setHasAlternateAddressError(undefined);
              setAlternateAddressFormVisible(false);
            }}
          />
        </Box>
      )}

      {!(isMobile && alternateAddressFormVisible) && (
        <>
          {alternateAddressFormVisible ? null : details?.shippingType ? (
            <Flex flexWrap="wrap">
              <Flex w="100%">
                <ShippingOption
                  alternateShippingAddress={
                    detailsOptions.alternateShippingAddress
                  }
                  collectDefaultAddress={detailsOptions.defaultShippingAddress}
                  shipping={details.shipping}
                  shippingMethods={validShippingMethods}
                  shippingType={
                    detailsOptions.shippingType || CollectShippingType.Default
                  }
                  tax={details.tax}
                />
                <Box flex="1" textAlign="right">
                  {optionsVisible
                    ? availableShippingTypes.length > 1 && (
                        <Text
                          variant={ETextVariant.XS2Mono}
                          color={EColor.Neutral65}
                          fontWeight="700"
                          textTransform="uppercase"
                          mb="10px"
                        >
                          {t("cart.shipping.selected")}
                        </Text>
                      )
                    : !isMobile && (
                        <Button
                          {...styles.editButton}
                          onClick={() => setOptionsVisible(true)}
                        >
                          {t("btn.change")}
                        </Button>
                      )}
                </Box>
              </Flex>

              {hasShippingMethods && (
                <Box w="100%">
                  <ShippingMethods
                    availableShippingMethods={validShippingMethods}
                    onClick={(shippingMethodId) =>
                      setDetailsOptions({
                        ...detailsOptions,
                        shippingMethodId,
                      })
                    }
                    selectedShippingMethodId={
                      detailsOptions.shippingMethodId ??
                      validShippingMethods[0].id
                    }
                  />
                </Box>
              )}
              {hasShippingMethods && (
                <Alert
                  className="mb-0 mt-2 bg-blue-100"
                  description={t("cart.shipping.burbank_banner.message")}
                  title={t("cart.shipping.burbank_banner.title")}
                />
              )}
            </Flex>
          ) : (
            <Box w="100%" textAlign="center">
              <Spinner />
            </Box>
          )}

          {optionsVisible && (
            <>
              <Box pt="8px" mt="16px" borderColor={EColor.Neutral15}>
                {availableShippingTypes.map((shippingType) => {
                  if (
                    shippingType &&
                    shippingTypeIsSet(shippingType, detailsOptions)
                  ) {
                    const shippingMethods = getValidShippingMethods(
                      shippingType,
                      details?.availableShippingMethods,
                      detailsOptions.defaultShippingAddress,
                      detailsOptions.alternateShippingAddress,
                    );

                    return (
                      <Box
                        key={shippingType}
                        borderTop="1px"
                        borderColor={EColor.Neutral15}
                        py="16px"
                      >
                        <ShippingOption
                          alternateShippingAddress={
                            detailsOptions.alternateShippingAddress
                          }
                          collectDefaultAddress={
                            detailsOptions.defaultShippingAddress
                          }
                          shipping={details?.shipping}
                          shippingMethods={shippingMethods}
                          shippingType={shippingType}
                          tax={details?.tax}
                          collectDefaultAddressError={hasDefaultAddressError}
                        />
                        <Link
                          {...styles.selectLink}
                          as="button"
                          onClick={() => {
                            setDetailsOptions({
                              ...detailsOptions,
                              shippingMethodId: shippingMethods[0]?.id,
                              shippingType,
                            });
                          }}
                        >
                          {t("btn.select")}
                        </Link>
                      </Box>
                    );
                  }
                })}
              </Box>

              {!alternateAddressFormVisible &&
                (isMobile ? (
                  <Flex
                    as="button"
                    borderTop="1px"
                    borderBottom="1px"
                    borderColor={EColor.Neutral15}
                    py="20px"
                    w="100%"
                    onClick={() => {
                      setAlternateAddressFormVisible(true);
                    }}
                  >
                    <Text {...styles.h3} mt={{ base: "4px", md: 0 }}>
                      {t("cart.shipping.alternate.form_title")}
                    </Text>
                    <Box flex={1} textAlign="right">
                      <ArrowIcon
                        transform="scaleX(-1)"
                        height="16px"
                        width="10px"
                        mr="18px"
                      />
                    </Box>
                  </Flex>
                ) : (
                  <Flex
                    alignItems="center"
                    borderTop="1px"
                    borderColor={EColor.Neutral15}
                    pt="20px"
                  >
                    <Text {...styles.h3} mb="0">
                      {t("cart.shipping.alternate.form_title")}
                    </Text>
                    <Box flex={1} textAlign="right">
                      <Button
                        {...styles.editButton}
                        px="23px"
                        onClick={() => {
                          setOptionsVisible(false);
                          setAlternateAddressFormVisible(true);
                        }}
                      >
                        {detailsOptions.alternateShippingAddress
                          ? t("btn.change")
                          : t("btn.add")}
                      </Button>
                    </Box>
                  </Flex>
                ))}
            </>
          )}
        </>
      )}
    </>
  );

  return isMobile && optionsVisible ? (
    <CheckoutModal isOpen={true}>
      {alternateAddressFormVisible ? (
        <CheckoutModalHeader
          onBackButtonClick={() => {
            setAlternateAddressFormVisible(false);
          }}
        >
          {t("cart.shipping.alternate.form_title")}
        </CheckoutModalHeader>
      ) : (
        <CheckoutModalHeader
          {...styles.h2}
          onBackButtonClick={() => {
            setOptionsVisible(false);
          }}
        >
          {t("cart.shipping.title")}
        </CheckoutModalHeader>
      )}
      <ModalBody p="24px">{bodyContent}</ModalBody>
    </CheckoutModal>
  ) : (
    <Box
      {...styles.sectionBox}
      pb={{ base: "30px", md: alternateAddressFormVisible ? "4px" : "20px" }}
      overflow="hidden"
    >
      {isMobile ? (
        <Flex alignItems="center" justifyContent="space-between" mb="14px">
          <Text {...styles.h2} mb={0}>
            {t("cart.shipping.title")}
          </Text>
          <Button
            {...styles.editButton}
            onClick={() => setOptionsVisible(true)}
          >
            {t("btn.change")}
          </Button>
        </Flex>
      ) : (
        <Text {...styles.h2}>{t("cart.shipping.title")}</Text>
      )}

      {bodyContent}

      {hasCombinedShippingBanner(optionsVisible, detailsOptions, details) &&
        !alternateAddressFormVisible && (
          <Flex
            alignItems="center"
            gap="16px"
            {...styles.banner}
            mb={{ base: "-30px", md: "-20px" }}
            mr={{ base: "-16px", md: "-24px" }}
            ml="-24px"
            mt="24px"
          >
            <InfoIcon />
            <Box>
              <Text variant={ETextVariant.Heading3} lineHeight="24px">
                {t("cart.shipping.banner.title")}
              </Text>
              <Text variant={ETextVariant.XS}>
                {t("cart.shipping.banner.message")}
              </Text>
            </Box>
          </Flex>
        )}
    </Box>
  );
};
