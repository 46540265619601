import { clsx, ClassValue } from "clsx";
import { ComponentType, lazy } from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { MONOLITH_URL } from "@/env";

const customTwMerge = extendTailwindMerge<
  | "text"
  | "screens"
  | "width"
  | "height"
  | "backgroundImage"
  | "boxShadow"
  | "dropShadow"
  | "animation"
  | "transitionDuration"
  | "fontFamily"
>({
  extend: {
    classGroups: {
      // Custom screen sizes
      screens: ["xs", "mobOnly", "mobOnlyTiny"],
      // Custom dimensions
      width: ["w-54"],
      height: ["h-18"],

      boxShadow: ["shadow-xs", "shadow-sm", "shadow-base", "shadow-lg"],
      // Custom drop shadows
      dropShadow: [
        "drop-shadow-sm",
        "drop-shadow-base",
        "drop-shadow-lg",
        "drop-shadow-xl",
      ],

      // Custom animations
      animation: [
        "animate-fadeIn",
        "animate-accordion-down",
        "animate-accordion-up",
        "animate-slide-down",
        "animate-slide-up",
      ],

      // Custom transition durations
      transitionDuration: ["duration-840"],

      // Custom fonts
      fontFamily: ["font-ABCDiatype", "font-mono"],
      text: [
        // Custom font sizes
        "text-headline-8xl",
        "text-headline-7xl",
        "text-headline-6xl",
        "text-headline-5xl",
        "text-headline-4xl",
        "text-headline-3xl",
        "text-headline-2xl",
        "text-headline-xl",
        "text-body-lg",
        "text-body-lg-bold",
        "text-body-md",
        "text-body-md-bold",
        "text-body-sm",
        "text-body-sm-bold",
        "text-body-xs",
        "text-body-xs-bold",
        "text-body-2xs",
        "text-body-2xs-bold",
      ],
    },
  },
});

export const cn = (...inputs: ClassValue[]) => customTwMerge(clsx(inputs));

export const getFaviconPath = () => {
  if (process.env.NODE_ENV === "development") {
    return "/favicon-dev.png";
  }
  if (MONOLITH_URL.includes("dev")) {
    return "/favicon-dev.png";
  }
  if (MONOLITH_URL.includes("qa")) {
    return "/favicon-qa.png";
  }
  if (MONOLITH_URL.includes("uat")) {
    return "/favicon-uat.png";
  }
  return "/favicon.ico";
};

// named imports for React.lazy: https://github.com/facebook/react/issues/14603#issuecomment-726551598
export function lazyImport<
  T extends ComponentType,
  I extends { [K2 in K]: T },
  K extends keyof I,
>(factory: () => Promise<I>, name: K): I {
  return Object.create({
    [name]: lazy(() => factory().then((module) => ({ default: module[name] }))),
  });
}
