import { useQuery } from "@apollo/client";
import { t } from "i18next";
import { useState } from "react";

import { shippingAddress2Alternate } from "@/components/ShippingSection/utils";
import {
  collectCheckoutDetails,
  CollectAlternatingShippingAddress,
  CollectCheckoutDetails,
  CollectInvoice,
  CollectListing,
  CollectListingType,
  CollectShippingMethod,
  CollectShippingType,
  collectCurrentUser,
  CollectAddress,
} from "@/gql";
import { useCartContext } from "@/hooks";

export interface ICheckoutDetailsOptions {
  alternateShippingAddress?: CollectAlternatingShippingAddress;
  defaultShippingAddress?: CollectAddress | null;
  invoiceId?: CollectInvoice["id"];
  listingId?: CollectListing["id"];
  listingType?: CollectListingType;
  shippingMethodId?: CollectShippingMethod["id"];
  shippingType?: CollectShippingType;
}

// Returns the collectCheckoutDetails input for an invoice if the ID exists,
// otherwise a listing. Adds the default shipping options if none were chosen.
export const getInputOptions = (
  {
    alternateShippingAddress,
    invoiceId,
    listingId,
    listingType,
    shippingMethodId,
    shippingType,
  }: ICheckoutDetailsOptions,
  {
    shippingMethod: defaultShippingMethod,
    shippingType: defaultShippingType,
  }: CollectCheckoutDetails | Record<string, never> = {},
) => {
  const selectedShippingType = shippingType ?? defaultShippingType;
  return {
    alternateShippingAddress:
      selectedShippingType === CollectShippingType.Default
        ? undefined
        : alternateShippingAddress,
    shippingMethodId: shippingMethodId ?? defaultShippingMethod?.id,
    shippingType: selectedShippingType,
    ...(invoiceId ? { invoiceId } : { listingId }),
    ...(listingId && !invoiceId && { listingType: listingType }),
  };
};

export const useCheckoutDetails = (detailsOptions: ICheckoutDetailsOptions) => {
  const { setGlobalErrorMessage, setDetailsOptions } = useCartContext();
  const { data: userData } = useQuery(collectCurrentUser);

  const [details, setDetails] = useState<CollectCheckoutDetails>();

  const { error, loading } = useQuery(collectCheckoutDetails, {
    onCompleted: (data) => {
      // Store details in state to prevent returning undefined during refetch.
      setDetails(data.collectCheckoutDetails as CollectCheckoutDetails);
      const alternateShippingAddress =
        data.collectCheckoutDetails?.shippingType === CollectShippingType.New &&
        data.collectCheckoutDetails?.shippingAddress
          ? shippingAddress2Alternate(
              data.collectCheckoutDetails?.shippingAddress,
            )
          : undefined;
      if (
        data.collectCheckoutDetails?.shippingType === CollectShippingType.New &&
        data.collectCheckoutDetails.shippingAddress &&
        !detailsOptions.alternateShippingAddress
      ) {
        setDetailsOptions({
          ...detailsOptions,
          shippingType:
            detailsOptions.shippingType ||
            data?.collectCheckoutDetails?.shippingType,
          defaultShippingAddress:
            userData?.collectCurrentUserV2?.collectDefaultAddress,
          alternateShippingAddress: alternateShippingAddress,
        });
      }
      if (
        data.collectCheckoutDetails?.shippingType !== CollectShippingType.New &&
        data?.collectCheckoutDetails?.shippingAddress &&
        !detailsOptions.defaultShippingAddress
      ) {
        setDetailsOptions({
          ...detailsOptions,
          shippingType: data?.collectCheckoutDetails?.shippingType,
          defaultShippingAddress:
            userData?.collectCurrentUserV2?.collectDefaultAddress,
        });
      }
    },
    onError: (apolloError) => {
      console.error("collectCheckoutDetails error: ", apolloError.message);

      if (apolloError.message === "mp_listing_already_sold") {
        setGlobalErrorMessage(t("cart.error.unavailable"));
        return;
      }

      // 'mp_unrecognized_client_error' or 'mp_get_tax_error' can indicate that an invalid
      // address was submitted, so let it pass through.
      if (
        (detailsOptions.alternateShippingAddress &&
          apolloError.message === "mp_unrecognized_client_error") ||
        apolloError.message === "mp_get_tax_error"
      ) {
        return;
      }

      setGlobalErrorMessage(t("cart.error.unexpected"));
      return;
    },
    skip: !(detailsOptions.invoiceId || detailsOptions.listingId),
    variables: {
      input: detailsOptions.invoiceId
        ? { invoice: getInputOptions(detailsOptions, details) }
        : { listing: getInputOptions(detailsOptions, details) },
    },
  });

  // To avoid a second render after the initial fetch, return `loading: true`
  // until the `detailsOptions` have been set.
  return { details, error, loading };
};
